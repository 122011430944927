import React from "react";
import BlockTitle from "./BlockTitle";
import { Link as ScrollLink } from "react-scroll";


const Services = () => {
  return (
    <section className="service-two" id="features">
      <div className="container">
        <BlockTitle
          textAlign="center"
          // paraText="Features"
          titleText={`Für Therapeuten`}
        />
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-app-development"></i>
                </div>
                <h3>Einfach</h3>
                <p>Einen Service oder Event erstellen </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development1"></i>
                </div>
                <h3>Schneller</h3>
                <p>Kunden finden für noch offene Termine</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development1"></i>
                </div>
                <h3></h3>
                <p>Lorem ipsum is are many variations of pass majy.</p>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-responsive"></i>
                </div>
                <h3>Gratis Abo</h3>
                <p>Ausprobieren mit 3 aktiven Angeboten</p>
                <a href="#fragen" className="thm-btn-small ">
                <span>mehr dazu </span>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

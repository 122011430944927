import React from "react";
import BlockTitle from "./BlockTitle";

import CtaShape1 from "../assets/images/shapes/cta-1-shape-1.png";
import CtaShape2 from "../assets/images/shapes/cta-1-shape-2.png";
import CtaMoc1 from "../assets/images/resources/cta-1-alt-med_rom2.png";

const CTAOne = () => {
  return (
    <section className="cta-one">
      <img src={CtaShape1} className="cta-one__bg-shape-1" alt="awesome post" />
      <img src={CtaShape2} className="cta-one__bg-shape-2" alt="awesome post" />
      <div className="container">
        <div className="cta-one__moc wow fadeInLeft" data-wow-duration="1500ms">
          <img src={CtaMoc1} className="cta-one__moc-img" alt="awesome post" />
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <BlockTitle
                textAlign="left"
                paraText="Alt-Med App"
                titleText={`Die neue App aus  \n Zürich`}
              />
              <div className="cta-one__text">
                <p>
                  Die Alternativ Medizin Plattform <br></br>für spontane Termin Buchungen
                </p>
              </div>
              <ul className="list-unstyled cta-one__list">
                <li>
                  <i className="fa fa-check-circle"></i>
                  Spontane Buchungen
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>
                  Schnelle Übersicht 
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>
                  Für Android & iPhone 
                </li>
              </ul>
              <a href="#download" className="thm-btn cta-one__btn">
                <span>jetzt downloaden</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;

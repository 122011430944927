import React from "react";
import BlockTitle from "./BlockTitle";
import ContactBgShape from "../assets/images/shapes/contact-bg-shape-1-1.png";
import ContactImage from "../assets/images/resources/contact_img.jpg";

import { toast } from 'react-toastify'


const Contact = () => {

  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        toast('Ihre Anfrage wurde erfolgreich gesendet',{ type: 'success' })
      } else {
        toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
      }
    }
    xhr.send(data)
  }

  return (
    <section className="contact-one" id="kontakt">
      <img
        src={ContactBgShape}
        className="contact-one__bg-shape-1"
        alt="kontakt alt-med"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <form className="contact-form-validated contact-one__form"
            method="POST"
            onSubmit={submitForm}
            action="https://formspree.io/f/xyylkzew"
           >
              <BlockTitle
                textAlign="left"
                // paraText="Contact Now"
                titleText={`Haben Sie Fragen? Senden Sie mir \n eine Nachricht`}
              />
              <div className="row">
                <div className="col-lg-6">
                  <input type="text" placeholder="Name" name="name" required />
                </div>
                <div className="col-lg-6">
                  <input type="text" placeholder="Email" name="email" required />
                </div>
                <div className="col-lg-6">
                  <input type="text" placeholder="Betreff" name="subject" />
                </div>
                <div className="col-lg-6">
                  <input type="text" placeholder="Telefon" name="phone" />
                </div>
                <div className="col-lg-12">
                  <textarea
                    placeholder="Nachricht"
                    name="message"
                    required
                  ></textarea>
                </div>
                <div className="col-lg-12 text-left">
                  <button type="submit" className="thm-btn contact-one__btn">
                    <span>Senden</span>
                  </button>
                </div>
              </div>
            </form>
            <div className="result"></div>
          </div>
          <div
            className="col-lg-5 d-flex wow fadeInRight"
            data-wow-duration="1500ms"
          >
            <div className="my-auto">
              <div className="contact-one__image">
                <img src={ContactImage} alt="awesome post" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

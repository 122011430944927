import React from "react";
import BlockTitle from "./BlockTitle";

const ServicesK = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <BlockTitle
          textAlign="center"
          // paraText="Features"
          titleText={`Für Users`}
        />
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-computer-graphic"></i>
                </div>
                <h3>Einfach</h3>
                <p>Übersicht der Heute noch verfügbaren Termine</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development"></i>
                </div>
                <h3>Spontan</h3>
                <p>Services oder Events finden und buchen</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-development1"></i>
                </div>
                <h3></h3>
                <p>Lorem ipsum is are many variations of pass majy.</p>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-4">
            <div className="service-one__single">
              <div className="service-one__inner">
                <div className="service-one__circle"></div>
                <div className="service-one__icon">
                  <i className="apton-icon-responsive"></i>
                </div>
                <h3>Gratis</h3>
                <p>Buchen und Therapeut in Bar bezahlen.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesK;

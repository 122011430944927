import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import faqBG from "../assets/images/shapes/faq-bg-1-1.png";

const FAQ = (props) => {
  return (
    <section className="faq-one" id="fragen">
      <img src={faqBG} className="faq-one__bg-shape-1" alt="häufige Fragen zu alt-med" />
      <Container>
        <BlockTitle
          textAlign="center"
          // paraText="Häufige Fragen"
          titleText={`Fragen zur App`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
            title="Gratis ?"
            content="Ja. Vielleicht nicht für immer aber sicher während der Pandemie ist unser Ziel die Therapeuten 
            so gut wie möglich zu unterstützen. Die Limitation beim Gratis Abo ist, dass maximal 3 aktive Resourcen gleichzeit laufen können. Wir empfehlen natürlich das Standard Abo. "
            status={false}
          />
          <AccordionItem
            title="Aktive Angebote?"
            content="Ein aktives Angebot ist ein Service, ein Event oder ein Deal, welcher öffentlich zur Buchung freigegeben ist.
            Sobald dieses Angebot abgeschlossen ist, d.h. gebucht und ausgeführt, ist das Angebot nicht mehr aktiv. Das Gleiche gilt wenn niemand das Angebot gebucht hat bis zum Anfang des Termins wird dieses Angebot nicht mehr als aktiv gewertet."
            status={true}
          />
          <AccordionItem
            title="3% Gebühren für das Standard Abo"
            content="Wir verrechnen nur eine Gebühr, falls das Angebot gebucht wurde und der Kunde auch aufgetaucht ist. Unser System verlangt vom Therapeuten nach dem Ende des Termins auszuwählen, ob der Service oder Event erfolgreich durchgeführt werden konnte. "
            status={true}
          />
          <AccordionItem
            title="Termin verpasst?"
            content="Sollte ein Kunde den Termin verpasst haben, so kann man das unserem System nach ablaufzeit der Buchung bekanntgeben und wir werden dem Kunden automatisch eine email senden. Sollte dies öfters vorkommen, werden wir den Account des Kunden einfrieren. Für verpasste Termine verlangen wir keine Gebühren ."
            status={false}
          />
          <AccordionItem
            title="Buchung abbrechen"
            content="Man kann eine Buchung jederzeit auflösen. Falls der Kunde die Buchung auflöst wird das Angebot wieder in die Liste der noch verfügbaren Angebote gestellt. Falls Buchungen öfters zu kurzfristig abgesagt werden, so werden wir den Account automatisch einfrieren. Mehr dazu in unseren AGBs "
            status={false}
          />
        </div>
      </Container>
    </section>
  );
};

export default FAQ;

import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./BlockTitle";

const Pricing2 = (props) => {
  const [plan, setPlan] = useState(false);
  return (
    <section className="pricing-one" id="pricing">
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Unsere Abonnemente"
          titleText={`Kosten für Therapeuten`}
        />
        {/* <ul
          className="list-inline text-center switch-toggler-list"
          id="switch-toggle-tab"
        >
          <li className={`month ${plan === false ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(false);
              }}
            >
              Monatlich Kosten
            </a>
          </li> */}
          {/* <li>
            <div
              role="button"
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              className={`switch ${plan === true ? "off" : "on"}`}
            >
              <span className="slider round"></span>
            </div>
          </li> */}
          {/* <li className={`year ${plan === true ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(true);
              }}
            >
              Annualy
            </a>
          </li> 
        </ul> */}
        {/* {plan === true ? (
          <div id="yearly">
            <Row>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Basic Pack</p>
                    <h3>$59.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Standard Pack</p>
                    <h3>$79.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Ultimate Pack</p>
                    <h3>$99.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Abonnements</span>
                    </a>

                    <span>Keine versteckten Gebühren</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : ( */}
        
          <div id="month">
            <Row>
              <Col lg={6}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Basis</p>
                    <h3>Gratis</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Alle Features</li>
                      <li>Gratis support</li>
                      <li>Limitation: 3 aktive Angebote</li>
                      {/* <li>Full access</li> */}
                    </ul>
                    {/* <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a> */}

                    <span>Keine versteckte Gebühren!</span>
                    <span>Keine Ablaufzeit während der Pandemie</span>

                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Standard Abo</p>
                    <h3>5 Fr. / Monat <br />  3% Gebühr *</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Alle Features</li>
                      <li>Gratis support</li>
                      <li>unlimitierte Angebote</li>
                      {/* <li>Full access</li> */}
                    </ul>
                    {/* <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a> */}

                    <span>* Wir verlangen nur eine Gebühr für erfolgreich abgeschlossene Angebote</span>
                  </div>
                </div>
              </Col>
              {/* <Col lg={4}>
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Ultimate Pack</p>
                    <h3>$49.00</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Extra features</li>
                      <li>Lifetime free support</li>
                      <li>Upgrate options</li>
                      <li>Full access</li>
                    </ul>
                    <a href="#none" className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>

                    <span>No hidden charges!</span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        
      </Container>
    </section>
  );
};

export default Pricing2;
